import { Footer } from "../../components/Footer";
import { Link } from "react-router-dom";

export const GSBCategorization = () => {
  return (
    <>
      {/* <!-- ======= Header ======= --> */}
      <header id="header" class="d-flex align-items-center header-transparent ">
        <div class="container d-flex align-items-start justify-content-between">
          <div class="logo">
            {/* <!-- <h1><a href="index.html"><span>Bootslander</span></a></h1> -->
        <!-- Uncomment below if you prefer to use an image logo --> */}
            <Link to="/">
              <img src="img/logo.svg" alt="" class="img-fluid" />
            </Link>
          </div>

          <nav id="navbar" class="navbar">
            <ul>
              <li>
                <a class="nav-link btn btn-light scrollto" href="https://backend.Over11.com/downloads">
                  {" "}
                  <i class="ri-download-fill me-2"></i> Download App
                </a>
              </li>
            </ul>
          </nav>

          {/* <!-- .navbar --> */}
        </div>
      </header>
      {/* <!-- End Header --> */}

      <section class="inner_hero_sec">
        <div class="heading">
          <h1>GSB Categorization  </h1>
        </div>
      </section>

      <main id="main">
        <section class="breadcrumbs">
          <div class="container">
            <div class="d-flex justify-content-between align-items-center">
              <ol>
                <li>
                  <a href="index.html">Home</a>
                </li>
                <li> GSB Categorization </li>
              </ol>
            </div>
          </div>
        </section>
        {/* <!-- End Breadcrumbs Section --> */}

        <section class="inner-page">
          <div class="container">
            <div class="content">
              <div class="TermsCondtns">
                  <div class="TermsCondtnsBox">

                  <div class="termsContent ftFx13 terms_condition_list">
                      <h4> GSB Categorization  </h4>
                      <div class="t_c_content mb-4 mb-md-5  ">
                        <p class="pdLt20"> 
                        Our special and meticulously designed feature of Gold/ Silver/ Bronze Categorization will save you by your own cricketing knowledge. Now your Risky Punts will reward you more than ever. 

                        
                        </p>
                        
                        <p class="pdLt20"> 
                          <i>The more Bronze players you have, the riskier the team it gets. While more Gold players represent Safe team, Silver means somewhere in between.</i>
                        </p>
                        <p>
                        A player's total fantasy points get multiplied by his/her respective multiplier depending on his/her respective category. 
                        </p>
                      </div>


                      <p>
                       Here are the <b> Multipliers:</b>
                      </p>
                      <div class="t_c_content  mt-3   mb-4 mb-md-5 "> 

                        <p>
                         <b>Gold - 1.1x</b>
                        </p>
                        <p>
                         <b>Silver - 1.2x</b>
                        </p>
                        <p>
                         <b>Bronze - 1.3x</b>
                        </p>


                        <p>Example - A Batter scores 100 fantasy points. If he is Gold, he gets 110 points(100*1.1) . If he is Silver, he gets 120 points(100*1.2) . If he is Bronze, he gets 130 points(100*1.3). </p>

                        <p>
                        And Suppose the same batter to be your Team's Captain means 2x additional points. 
                        </p>

                        <p>
                        That is, 
                        </p>


                        <p> If he's Gold, he gets 100*1.1*2 =220 points </p>
                        <p> If he's Silver, he gets 100*1.2*2 = 240 points</p> 
                        <p> If he's Bronze, he gets 100*1.3*2 =260 points </p>

                      </div>



                       

                     
                      


                  </div>
                  
                  </div>
                </div>
            </div>
          </div>
        </section>
      </main>
      {/* <!-- End #main --> */}

      {/* <!-- ======= Footer ======= --> */}
      <Footer />
      {/* <!-- End Footer --> */}

      <a
        href="#"
        class="back-to-top d-flex align-items-center justify-content-center"
      >
        <i class="bi bi-arrow-up-short"></i>
      </a>
      {/* <div id="preloader"></div> */}
    </>
  );
};
