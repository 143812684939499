// import { Footer } from "../../components/Footer";
import { Link } from "react-router-dom";

export const HowToPlayMobile = () => {
  return (
    <>
      {/* <!-- ======= Header ======= --> */}
      {/* <header id="header" class="d-flex align-items-center header-transparent ">
        <div class="container d-flex align-items-start justify-content-between">
          <div class="logo"> 
            <Link to="/">
              <img src="img/logo.svg" alt="" class="img-fluid" />
            </Link>
          </div>

          <nav id="navbar" class="navbar">
            <ul>
              <li>
                <a class="nav-link btn btn-primary scrollto" href="https://backend.criccrak.in/downloads">
                  {" "}
                  <i class="ri-download-fill me-2"></i> Download App
                </a>
              </li>
            </ul>
          </nav>
 
        </div>
      </header> */}
      {/* <!-- End Header --> */}

      <section class="inner_hero_sec  mt-0">
        <div class="heading">
          <h1>How To Play</h1>
        </div>
      </section>

      <main id="main">
        {/* <section class="breadcrumbs">
          <div class="container">
            <div class="d-flex justify-content-between align-items-center">
              <ol>
                <li>
                  <a href="index.html">Home</a>
                </li>
                <li>How To Play</li>
              </ol>
            </div>
          </div>
        </section> */}
        {/* <!-- End Breadcrumbs Section --> */}

        <section class="inner-page">
          <div class="container">
          <div class="content">
              <div class="TermsCondtns">
                  <div class="TermsCondtnsBox">

                  <div class="termsContent ftFx13 terms_condition_list">
                       
                      

                      <p>
                      Step <b>1) Register:</b>
                      </p>
                      <div class="t_c_content  mt-3   mb-4 mb-md-5 "> 

                        <p>
                        Download our Over11 App via Play store or Visit our website Criccrak.in to download and register via Mobile number.
                        </p> 

                      </div>



                      <p>
                      Step <b>2) Select a Match:</b>
                      </p>
                      <div class="t_c_content  mt-3   mb-4 mb-md-5 "> 

                        <p>
                        Over11 App hosts a number of different matches across International and Domestic Leagues happening around the globe. Click on an upcoming match you want to play and keep an eye on the match deadline.
                        </p> 

                      </div>



                      <p>
                      Step <b>3) Make your team:</b>
                      </p>
                      <div class="t_c_content  mt-3   mb-4 mb-md-5 "> 

                        <p>
                        Select 11 players from Gold, Silver and Bronze categories based on your risk appetite and knowledge among Wicket-Keepers, Batters, All-Rounders and Bowlers, to form your fantasy team with 100 credit points. 
                        </p> 


                        <p>
                          <b>Remember more Gold players means Safer Team, more Bronze players means Riskier Team. </b>
                        </p>


                        <p>
                        After selecting a team of 11 players, Pick a <b>Captain (2X points) and Vice-Captain (1.5X points)</b>, who you think will give maximum points. 
                        </p>


                        <br/>
                        <p>
                        Points to consider :
                        </p>

                        <p>  You can only pick a maximum of 10 players from one team.    </p>
                        <p>  You can pick 1-8 Wicket Keepers    </p>
                        <p> You can pick 1-8 Batters  </p>
                        <p> You can pick 1-8 All-Rounders  </p>
                        <p>You can pick 1-8 Bowlers  </p>

                        <br/>
                        <br/>

                       <b>  IMPORTANT NOTE:</b> Line-up is public information. Although we display it from the best sources possible, we suggest you verify from your sources as well.


                      </div>



                      <p>
                      Step <b>4) Select Substitutes</b> : 
                      </p>
                      <div class="t_c_content  mt-3   mb-4 mb-md-5 "> 

                        <p>
                        Picking a Substitute is an Option and not a Compulsion.
                        </p> 
                        

                        <p>
                        You can select Upto 4 Substitute(s) in the order of priority as Sub1, Sub2, Sub3, Sub4. Your Substitute(s) will come into action when your already chosen player(s) is not a part of the playing 11.
                        </p>

                      </div>



                      <p>
                      Step  <b>5) Select Contests:</b>
                      </p>
                      <div class="t_c_content  mt-3   mb-4 mb-md-5 "> 

                        <p>
                        There are multiple contests running on Over11 App. You can choose from Free, Paid and Private contests.
                        </p> 
                        

                        <p>
                        <b> Free contest:</b> If you are new to fantasy cricket, you can start with the free practice contest to improve your skills before moving to the paid contests. You can play practice contests for unlimited times.
                        </p>

                        <p>
                        <b>Paid contest:</b> If you are an experienced player and want to win prize money, you can play any paid contest and win. Choose from the various contests listed on the platform.
                        </p>

                        <p>
                        <b> Private contest:</b> You can also create your own private contest and play with your friends or family.
                        </p>

                      </div>


                      <p>
                      Step <b>6) Monitor your Players:  </b>
                      </p>
                      <div class="t_c_content  mt-3   mb-4 mb-md-5 "> 

                        <p>
                        Once the game begins, check the players’ performance in <b>Live section in My Contest</b> section in home 
                        screen. As the live match progresses, see your team’s scores 
                        increase based on the points system and Gold/Silver/Bronze Multiplier. 
                        </p> 
                         
                        <p>
                        For more information about the Scoring Elements, refer 
                        <b>  Fantasy Points System.</b>   </p>

                        

                      </div>



                      <p>
                      Step <b>7) Match is Completed </b>
                      </p>
                      <div class="t_c_content  mt-3   mb-4 mb-md-5 "> 

                        <p>
                        You will find the final score and rank of your team in the Completed section in My Contest. The Winning Positions are Announced as per the Ranks of different teams. These ranks are finalised based on the points accumulated by the joined teams as per the players’ performances in live matches. </p>
 
                      </div>

                      <p>
                      Step <b>8) Enjoy the Winnings :</b>
                      </p>
                      <div class="t_c_content  mt-3   mb-4 mb-md-5 "> 

                        <p>
                        Winners can play again with the Winning Amount or Withdraw their Winnings from Wallet Section
                        </p> 
                         
                        <p>
                        For More details about your Account Balance, Add Cash and Withdrawals, tap on the Wallet section of the app.   </p>

                        <b>
                        You’re ready to get started!
                        </b>  
                      </div> 
                  </div>
                  
                  </div>
                </div>
            </div>
          </div>
        </section>
      </main>
      {/* <!-- End #main --> */}

      {/* <!-- ======= Footer ======= --> */}
      {/* <Footer /> */}
      {/* <!-- End Footer --> */}

      {/* <a
        href="#"
        class="back-to-top d-flex align-items-center justify-content-center"
      >
        <i class="bi bi-arrow-up-short"></i>
      </a> */}
      {/* <div id="preloader"></div> */}
    </>
  );
};
