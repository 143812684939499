import { Footer } from "../../components/Footer";
import { Link } from "react-router-dom";

export const WalletSection = () => {
  return (
    <>
      {/* <!-- ======= Header ======= --> */}
      <header id="header" class="d-flex align-items-center header-transparent ">
        <div class="container d-flex align-items-start justify-content-between">
          <div class="logo">
            {/* <!-- <h1><a href="index.html"><span>Bootslander</span></a></h1> -->
        <!-- Uncomment below if you prefer to use an image logo --> */}
            <Link to="/">
              <img src="img/logo.svg" alt="" class="img-fluid" />
            </Link>
          </div>

          <nav id="navbar" class="navbar">
            <ul>
              <li>
                <a class="nav-link btn btn-light scrollto" href="https://backend.Over11.com/downloads">
                  {" "}
                  <i class="ri-download-fill me-2"></i> Download App
                </a>
              </li>
            </ul>
          </nav>

          {/* <!-- .navbar --> */}
        </div>
      </header>
      {/* <!-- End Header --> */}

      <section class="inner_hero_sec">
        <div class="heading">
          <h1>   Wallet Section </h1>
        </div>
      </section>

      <main id="main">
        <section class="breadcrumbs">
          <div class="container">
            <div class="d-flex justify-content-between align-items-center">
              <ol>
                <li>
                  <a href="index.html">Home</a>
                </li>
                <li> Wallet Section </li>
              </ol>
            </div>
          </div>
        </section>
        {/* <!-- End Breadcrumbs Section --> */}

        <section class="inner-page">
          <div class="container">
            <div class="content t_c_content">
              <h4 className="mb-3" ><b>How to Deposit : </b></h4>
              <p>In Over11 App, It is extremely <b>Easy to Deposit</b>. Just follow these steps :</p> 
              <ol>
                <li>
                 Go to <b>Wallet</b> Section in bottom right of <b>Home Screen</b>.
                </li>
                <li>
                   Click on <b>Add Cash</b> button and  <b>Enter the Amount.</b>
                </li>
                <li>
                   Use a deposit code to  <b>Receive the Bonus.</b>
                </li>
                <li>
                  <b>Use Any Deposit method</b> to make a deposit.
                </li>
              </ol>
            </div>
            <br/>
            <br/> 
            <div class="content t_c_content">
              <h4 className="mb-3" ><b>How to Withdraw  : </b></h4>
              <p> <b>Withdrawing on Over11 is extremely easy</b>. Just follow these steps:</p> 
              <ol>
                <li> Click on the <b>Wallet</b> section at the bottom right corner of the <b>Home Screen. Verify</b> and <b>Enter the Amount</b> you want to withdraw. </li>
                <li> Click on <b>Withdraw</b> and Choose a <b>Withdrawal Method.</b> </li>
                <li> You can choose UPI to withdraw money as low as ₹50 and Using Bank you can withdraw up to ₹1,00,000 per day. </li>
                <li> On choosing any other mode through bank, the money will be transferred within 24 to 48 hours. </li>
              </ol>
            </div>
            
          </div>
        </section>
      </main>
      {/* <!-- End #main --> */}

      {/* <!-- ======= Footer ======= --> */}
      <Footer />
      {/* <!-- End Footer --> */}

      <a
        href="#"
        class="back-to-top d-flex align-items-center justify-content-center"
      >
        <i class="bi bi-arrow-up-short"></i>
      </a>
      {/* <div id="preloader"></div> */}
    </>
  );
};
