// import { Footer } from "../../components/Footer";
import { Link } from "react-router-dom";
import { useState, useEffect } from "react";

export const WalletSectionMobile = () => {
  return (
    <> 

      <section class="inner_hero_sec  mt-0">
        <div class="heading">
          <h1>Wallet Section  </h1>
        </div>
      </section>

      <main id="main"> 
        <section class="inner-page">
        <div class="container">
            <div class="content t_c_content">
              <h4 className="mb-3" ><b>How to Deposit : </b></h4>
              <p>In Over11 App, It is extremely <b>Easy to Deposit</b>. Just follow these steps :</p> 
              <ol>
                <li>
                 Go to <b>Wallet</b> Section in bottom right of <b>Home Screen</b>.
                </li>
                <li>
                   Click on <b>Add Cash</b> button and  <b>Enter the Amount.</b>
                </li>
                <li>
                   Use a deposit code to  <b>Receive the Bonus.</b>
                </li>
                <li>
                  <b>Use Any Deposit method</b> to make a deposit.
                </li>
              </ol>
            </div>
            <br/>
            <br/> 
            <div class="content t_c_content">
              <h4 className="mb-3" ><b>How to Withdraw  : </b></h4>
              <p> <b>Withdrawing on Over11 is extremely easy</b>. Just follow these steps:</p> 
              <ol>
                <li> Click on the <b>Wallet</b> section at the bottom right corner of the <b>Home Screen. Verify</b> and <b>Enter the Amount</b> you want to withdraw. </li>
                <li> Click on <b>Withdraw</b> and Choose a <b>Withdrawal Method.</b> </li>
                <li> You can choose UPI to withdraw money as low as ₹50 and Using Bank you can withdraw up to ₹1,00,000 per day. </li>
                <li> On choosing any other mode through bank, the money will be transferred within 24 to 48 hours. </li>
              </ol>
            </div>
            
          </div>
        </section>
      </main>
       
    </>
  );
};
